import React, { lazy, Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import { ApiProvider } from "./api";
import { WebsocketContextProvider } from "./api/websocket/context";
import FieldsProvider from "./appField/fieldStore";
import { AuthProvider } from "./auth/authStore";
import { BillingPlanInfoContextProvider } from "./billing/hook";
import { BillingContextProvider } from "./billing/hook/BillingContextProvider";
import { BillingPlanContextProvider } from "./billing/hook/BillingPlanContextProvider";
import { VATInfoProvider } from "./billing/VATInfoProvider";
import PageLoader from "./common/pageLoader";
import { ResponsiveProvider } from "./common/responsiveStore";
import Meta from "./meta";
import { OrgGoalTrackingContextProvider } from "./org-goal-tracking/OrgGoalTrackingContextProvider";
import TagProvider from "./tag/tagStore";
const Login = lazy(() => import("./auth/login-page/login-page"));
const Register = lazy(() => import("./auth/register"));
const VerifyEmail = lazy(() => import("./auth/verify-email"));
const Token = lazy(() => import("./auth/token"));
const SsoRedirect = lazy(() => import("./auth/sso/page"));
const Logout = lazy(() => import("./auth/logout"));
const Unsubscribe = lazy(() => import("./unsubscribe/unsubscribe"));
const Notification = lazy(() => import("./unsubscribe/notification"));
const Dashboard = lazy(() => import("./dashboard/dashboardRoutes"));
const EmailVerification = lazy(() => import("./send/emailVerification"));
const PublicReport = lazy(() => import("./features/report/public"));
const PublicReportV2 = lazy(() => import("./features/report-v2/public/PublicReport"));
const Onboard = lazy(() => import("./onboard"));
const BlackWeek = lazy(() => import("./billing/offer/black-week"));
const providers = [
  ResponsiveProvider,
  ApiProvider,
  AuthProvider,
  TagProvider,
  FieldsProvider,
  WebsocketContextProvider,
  BillingPlanContextProvider,
  BillingContextProvider,
  BillingPlanInfoContextProvider,
  VATInfoProvider,
  OrgGoalTrackingContextProvider
];
const app = /* @__PURE__ */ React.createElement(Suspense, { fallback: /* @__PURE__ */ React.createElement(PageLoader, null) }, /* @__PURE__ */ React.createElement(Meta, null), /* @__PURE__ */ React.createElement(Switch, null, /* @__PURE__ */ React.createElement(Route, { path: "/blackweek", component: BlackWeek }), /* @__PURE__ */ React.createElement(Route, { path: "/login", component: Login }), /* @__PURE__ */ React.createElement(Route, { path: "/login/token", component: Login }), /* @__PURE__ */ React.createElement(Route, { path: "/register", component: Register }), /* @__PURE__ */ React.createElement(Route, { path: "/verify-email", component: VerifyEmail }), /* @__PURE__ */ React.createElement(Route, { path: "/sso/:service", component: SsoRedirect }), /* @__PURE__ */ React.createElement(Route, { path: "/token", component: Token }), /* @__PURE__ */ React.createElement(Route, { path: "/logout", component: Logout }), /* @__PURE__ */ React.createElement(Route, { path: "/unsubscribe", component: Unsubscribe }), /* @__PURE__ */ React.createElement(Route, { path: "/notification", component: Notification }), /* @__PURE__ */ React.createElement(Route, { path: "/onboard", component: Onboard }), /* @__PURE__ */ React.createElement(Route, { path: "/email-verification/:status", component: EmailVerification }), /* @__PURE__ */ React.createElement(Route, { path: "/r/:report_id", component: PublicReport }), /* @__PURE__ */ React.createElement(Route, { path: "/r2", component: PublicReportV2 }), /* @__PURE__ */ React.createElement(Route, { path: "/", component: Dashboard })));
function connectProviders(providers2, children) {
  let [provider, ...rest] = providers2;
  return React.createElement(provider, null, rest.length ? connectProviders(rest, children) : children);
}
export default connectProviders(providers, app);
