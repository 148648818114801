import React from "react";
import ReactDOM from "react-dom";
import "reactflow/dist/style.css";
import { App } from "./AppComponent";
import "./sass/app.sass";
import "./sentry";
const render = () => {
  ReactDOM.render(
    /* @__PURE__ */ React.createElement(React.StrictMode, null, /* @__PURE__ */ React.createElement(App, null)),
    document.getElementById("app")
  );
};
render();
if (module.hot) {
  module.hot.accept([], function(err) {
    console.error("An error occurred while accepting new version");
    console.error(err);
  });
}
